/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://axg7r6vxsraarnu3td3syildey.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-c2xmoam7jrdorpe7v5ytcdph4i",
    "aws_cognito_identity_pool_id": "us-east-1:50ada68e-2c13-4515-8d0a-27951fa6c47c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QYCnKaR8k",
    "aws_user_pools_web_client_id": "2dkhahcbqv84enjb53n4tt8i3b",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tjs233055-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
